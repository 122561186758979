import React from "react"
import ActuateMinds from "../components/actuate-minds/actuate-minds"
import HomePage from "../components/home"
import Layout from "../components/layout/layout"
import Seo from "../components/layout/seo"

export default function Home() {
  return (
    <Layout active={"home"}>
      <Seo title="We guide kids how to think, not what to think" />
      <HomePage />
      <ActuateMinds/>
    </Layout>
  )
}
