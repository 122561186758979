import { Link } from "gatsby"
import React from "react"
import { Accordion } from "react-bootstrap"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import appleStore from "../../assets/images/apple-store.svg"
import BackgroundImage from "../../assets/images/Background-image.svg"
// import schoolLogo from "../../assets/images/school-logo.svg"
import Chatbox01 from "../../assets/images/Chatbox-01.svg"
import Chatbox02 from "../../assets/images/Chatbox-02.svg"
import Chatbox03 from "../../assets/images/Chatbox-03.svg"
import Cloud from "../../assets/images/cloud.svg"
import DownArrow from "../../assets/images/DownArrow.svg"
import googlePlay from "../../assets/images/google-play.svg"
import GreyBackground from "../../assets/images/Grey-background.svg"
import LeftArrow from "../../assets/images/LeftArrow.svg"
import rightArrow from "../../assets/images/right-arrow.svg"
import RightArrow from "../../assets/images/RightArrow.svg"
import studentByHeart from "../../assets/images/student-by-heart.svg"
import clearsExams from "../../assets/images/Student-clear-exame.svg"
import assessment from "../../assets/images/student-gives-assessment.svg"
import reAssessment from "../../assets/images/student-gives-re-assessment.svg"
import raiseHand from "../../assets/images/student-raise-hand.svg"
import student from "../../assets/images/student.svg"
import teacherTeach from "../../assets/images/teacher-teach.svg"
import teacher from "../../assets/images/teacher.svg"
import Girl from "../../assets/images/ThinkingGirl.svg"
import UpArrow from "../../assets/images/UpArrow.svg"
import WeakStudents from "../../assets/images/Weak-students.svg"
import "../../assets/styles/hero.scss"

const HomePage = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }
  return (
    <>
      <div className="hero-box">
        <div className="container d-md-flex justify-content-md-between pt-hero">
          <div className="col-md-7 mx-md-4 mx-2">
            <h1 className="mt-md-5 pt-md-4 mr-md-5 fnt-mlg-35 fnt-lg-45 fnt-24 fnt-bold fnt-lg-bold text-clr54 pr-text">
              Millions saw the apple fall, but Newton asked why.
            </h1>
            <div className="row mx-1 d-md-none">
              <div className="w-100 cloud-mob">
                <div className="cloud float-end">
                  <h6 className="fnt-600 text-black fnt-baby cloud-center">
                    Why don't birds get an electric shock when they sit on a
                    live wire?
                  </h6>
                  <img src={Cloud} alt="Cloud" className="w-100" />
                </div>
              </div>
              <div className="girl d-flex">
                <img src={Girl} alt="Girl" />
                <h5 className="mt-2 text-clr54 text-center fnt-sm">
                  Education is not about teaching kids how to read, but about
                  teaching them to question what they read. We guide children
                  how to think, not what to think.
                </h5>
              </div>
            </div>
            <p className="mt-md-5 fnt-18 fnt-medium fnt-mlg-20 fnt-lg-24 fnt-lg-medium text-clr54 d-none d-md-block">
              Education is not about teaching kids how to read, but about
              teaching them to question what they read. We guide children how to
              think, not what to think.
            </p>
            <div className="mt-md-4 pt-md-4">
              <h5 className="fnt-lg-18 fnt-lg-medium text-clr54 mb-25 text-center text-lg-start fnt-18">
                Download our App to check out our unmatched quality of
                questions.
              </h5>
              <div className="d-flex justify-content-between justify-content-lg-start pb-2 pb-md-3">
                <a
                  rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.actuateminds.beta"
                  target="_blank"
                  className="phone-btn fnt-md-18 fnt-500 bg-clr11 text-white mr-1"
                >
                  <img src={googlePlay} alt="google-play" />
                  &nbsp; Google Play
                </a>
                <a
                  rel="noreferrer"
                  href="https://apps.apple.com/us/app/actuate-minds/id1586452790"
                  target="_blank"
                  className="phone-btn fnt-md-18 fnt-500 bg-clr11 text-white"
                >
                  <img src={appleStore} alt="apple-store" /> &nbsp; App Store
                </a>
              </div>
            </div>
          </div>
          <div className="row ms-md-5 d-none d-md-block">
            <div className="w-100">
              <div className="cloud float-end">
                <p className="fnt-lg-24 fnt-lg-600 text-black fnt-baby cloud-center">
                  Why don't birds get an electric shock when they sit on a live
                  wire?
                </p>
                <img src={Cloud} alt="Cloud" className="w-100" />
              </div>
            </div>
            <div className="girl">
              <img src={Girl} alt="Girl" />
            </div>
          </div>
        </div>
        <div className="teaching-section bg-pink">
          <div className="container py-3">
            <h2 className="text-center fnt-lg-35 fnt-lg-bold text-clr54 py-md-5 py-3">
              Traditional Teaching
            </h2>
            <div className="d-md-flex justify-content-md-around">
              <div className="text-center col-md-3">
                <img
                  className="teach-img mb-md-3"
                  src={teacher}
                  alt="teacher"
                />
                <p className="fnt-18 fnt-500 fnt-lg-22 fnt-lg-500 px-5 mx-5 mx-md-0 text-clr54 fnt-sm-9">
                  Teacher teaches in class
                </p>
              </div>
              <div className="d-flex align-items-center right-arrow">
                <img src={rightArrow} alt="rightArrow" />
              </div>
              <div className="text-center col-md-3">
                <img
                  className="teach-img mb-md-3"
                  src={studentByHeart}
                  alt="Student-by-heart"
                />
                <p className="fnt-lg-22 fnt-18 fnt-500 fnt-lg-500 px-5 px-md-0 text-clr54 fnt-sm-9">
                  Student memorizes the definition and formula
                </p>
              </div>
              <div className="d-flex align-items-center right-arrow">
                <img src={rightArrow} alt="rightArrow" />
              </div>
              <div className="text-center col-md-3">
                <img
                  className="teach-img mb-md-3"
                  src={student}
                  alt="student"
                />
                <p className="fnt-18 fnt-500 fnt-lg-22 fnt-18 fnt-500 fnt-lg-500 text-clr54 fnt-sm-9">
                  Student passes the exam but the underlying concepts are still
                  not clear
                </p>
              </div>
            </div>
            <div className="text-center traditional-teaching mx-md-5">
              <p className="fnt-18 fnt-500 fnt-lg-24 fnt-lg-500 text-clr54 fnt-sm">
                Traditional teaching is “one size fits all”, whereas every child
                is different. Rather than student-focussed learning, the
                currently established methods of teaching rely on standards,
                curriculum and passing tests.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="help-section">
        <div className="container pb-md-4">
          <h2 className="text-center fnt-lg-35 fnt-lg-bold text-clr54 pt-4 pb-2 py-md-5">
            How we help students?
          </h2>
          <div className="pb-md-4 d-md-flex">
            <div className="d-md-flex align-items-md-center justify-content-md-end w-100">
              <div className="text-center help-process">
                <img
                  className="pb-md-2"
                  src={teacherTeach}
                  alt="teacher-teach"
                />
                <p className="fnt-lg-18 fnt-500 text-clr54 px-md-2 left-text">
                  Teacher teaches in class
                </p>
              </div>
              <div className="left-arrow">
                <img src={LeftArrow} alt="LeftArrow" />
              </div>
            </div>
            <div className="box d-none d-lg-block">
              <div className="box-center h-100 row w-100 mx-0">
                <div className="d-md-flex justify-content-md-around w-100 h-75 mt-md-3">
                  <div className="pl-4 text-center row">
                    <div className="help-process">
                      <img src={raiseHand} alt="raise-hand" />
                      <p className="fnt-lg-18 fnt-lg-500 text-clr54 mb-0 wide-text">
                        <strong> Mind Actuators </strong> empowers students to
                        ask thought provoking questions
                      </p>
                    </div>
                    <div>
                      <img src={DownArrow} alt="DownArrow" />
                    </div>
                  </div>
                  <div className="mt-5 text-center row">
                    <div>
                      <img
                        className="arrow-position"
                        src={UpArrow}
                        alt="UpArrow"
                      />
                    </div>
                    <div className="help-process mt-xl-5">
                      <img className="mt-5" src={assessment} alt="assessment" />
                      <p className="fnt-lg-18 fnt-lg-500 text-clr54 text-center wide-text">
                        <strong> Actuate Test </strong> probes students on the
                        thorough understanding of concepts
                      </p>
                    </div>
                  </div>
                  <div className="text-center row">
                    <div className="help-process">
                      <img src={WeakStudents} alt="Re-work" />
                      <p className="fnt-lg-18 fnt-lg-500 text-clr54 mb-0 wide-text">
                        Advanced analytics identifies learning gaps and
                        communicates to teachers and parents
                      </p>
                    </div>
                    <div>
                      <img src={DownArrow} alt="DownArrow" />
                    </div>
                  </div>
                  <div className="text-center row">
                    <div className="mt-1 pt-md-5"></div>
                    <div className="help-process mt-xl-5 pt-md-5 pr-5">
                      <img
                        className="mt-5"
                        src={reAssessment}
                        alt="Re-Assessment"
                      />
                      <p className="fnt-lg-18 fnt-lg-500 text-clr54 wide-text">
                        Personalised follow up and
                        <strong> Reactuate Test </strong> for every student to
                        address the gaps
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <img
                className="grey-background"
                src={BackgroundImage}
                alt="BackgroundImage"
              />
            </div>

            <div className="box d-lg-none">
              <div className="box-center w-75">
                <div className="row text-center">
                  <div className="help-process col-6">
                    <img src={raiseHand} alt="raise-hand" />
                    <p className="fnt-16 fnt-500 text-clr54 mb-0 wide-text">
                      <strong> Mind Actuators </strong> empowers students to ask
                      thought provoking questions
                    </p>
                  </div>
                  <div className="col-6 d-flex justify-content-end align-items-center up-arrow">
                    <img src={UpArrow} alt="UpArrow" />
                  </div>
                </div>
                <div className="text-center row">
                  <div className="col-6 d-flex justify-content-end align-items-center down-arrow">
                    <img src={DownArrow} alt="DownArrow" />
                  </div>
                  <div className="help-process col-6">
                    <img src={assessment} alt="assessment" />
                    <p className="fnt-16 fnt-500 text-clr54 text-center wide-text">
                      <strong> Actuate Test </strong> probes students on the
                      thorough understanding of concepts
                    </p>
                  </div>
                </div>
                <div className="row text-center">
                  <div className="help-process col-6">
                    <img src={WeakStudents} alt="Re-work" />
                    <p className="fnt-16 fnt-500 text-clr54 mb-0 wide-text">
                      Advanced analytics identifies learning gaps and
                      communicates to teachers and parents
                    </p>
                  </div>
                  <div className="col-6 d-flex justify-content-end align-items-center up-arrow">
                    <img src={UpArrow} alt="UpArrow" />
                  </div>
                </div>
                <div className="text-center row justify-content-end">
                  <div className="help-process col-6">
                    <img src={reAssessment} alt="Re-Assessment" />
                    <p className="fnt-16 fnt-500 text-clr54 wide-text">
                      Personalised follow up and
                      <strong> Reactuate Test </strong> for every student to
                      address the gaps
                    </p>
                  </div>
                </div>
              </div>
              <img
                className="w-100"
                src={GreyBackground}
                alt="Grey-background"
              />
            </div>

            <div className="d-md-flex justify-content-start align-items-center w-100">
              <div className="am-right-arrow">
                <img src={RightArrow} alt="RightArrow" />
              </div>
              <div className="text-center help-process px-md-3">
                <img src={clearsExams} alt="clear-exams" />
                <p className="fnt-16 fnt-lg-18 fnt-lg-500 fnt-500 text-clr54 right-text">
                  Clear concepts allow students to excel in their exams
                </p>
              </div>
            </div>
          </div>
          <div className="mx-auto col-12 col-md-9 text-center approach">
            <p className="fnt-lg-20 fnt-lg-500 fnt-18 fnt-500 text-clrb5">
              Our approach is uniquely tailored considering the different needs
              of every student. At the center of our approach is the student,
              and value is built around the questions a student needs answers
              to, in order to benefit from the curriculum.
            </p>
          </div>
        </div>
      </div>
      <div className="who-section">
        <div className="corner bg-gray pt-4 pt-md-3">
          <div className="container">
            <h2 className="fnt-lg-35 fnt-lg-bold text-clr54 text-center py-md-4">
              Who we are?
            </h2>
            <div className="girl-with-lense">
              <p className="fnt-lg-22 fnt-18 fnt-500 fnt-lg-500 text-clr54 text-center mb-4 mx-md-4 px-md-5 fnt-sm">
                We are a group of education enthusiasts with a vision to help
                students realise their true potential by means of a journey
                guided by conceptual learning. We strive to make use of modern
                technological enhancements coupled with effective educational
                techniques to deliver the highest value to a child’s learning
                journey.
              </p>
              <p className="fnt-lg-22 fnt-18 fnt-500 fnt-lg-500 text-clr54 text-center mb-md-4 mx-md-4 px-md-5 pb-4 pb-md-5 fnt-sm">
                Our team consists of expert professionals who are former
                graduates from the likes of IITs and NITs, some of India’s
                finest universities. The group has a strong dynamic personality
                owing to the diverse mix of individuals brought together by
                their sheer love for all things maths and science.
              </p>
            </div>
          </div>
        </div>

        <div className="question-section pb-3 pb-md-4">
          <div className="container">
            <div className="py-md-2">
              <h2 className="fnt-lg-35 fnt-lg-bold text-clr54 text-center py-md-2">
                Still have some doubts?
              </h2>
            </div>
            <div className="mb-3">
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="button-icon fnt-sm">
                    Does Actuate Minds teach students?
                  </Accordion.Header>
                  <Accordion.Body className="fnt-sm">
                    No, our goal is not to teach students, our teachers are
                    perfectly capable of doing that; the goal is to ignite and
                    drive their curiosity to ask all kinds of questions which
                    will help them gain a firm grip over the concepts.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div className="my-md-3">
                <Accordion defaultActiveKey="0" flush>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header className="button-icon fnt-sm">
                      Will the Actuate Minds Program affect our school’s
                      schedule and syllabus?
                    </Accordion.Header>
                    <Accordion.Body className="fnt-sm">
                      No, we have designed the program in a way that allows
                      teachers to complete the syllabus with additional ease and
                      better results from the students.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="my-md-3">
                <Accordion defaultActiveKey="0" flush>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header className="button-icon fnt-sm">
                      Will students use the Actuate Minds App or schools will
                      get their own App?
                    </Accordion.Header>
                    <Accordion.Body className="fnt-sm">
                      Based on your requirements, we will create a personalized
                      app for your school.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="my-md-3">
                <Accordion defaultActiveKey="0" flush>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header className="button-icon fnt-sm">
                      How can my school join Actuate Minds?
                    </Accordion.Header>
                    <Accordion.Body className="fnt-sm">
                      Please fill out the <Link to="/contact-us/">contact</Link>{" "}
                      form, and we will get in touch with you.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="partners-secion bg-gray">
        <div className="container pt-md-4">
          <div className="">
            <h2 className="fnt-lg-35 fnt-lg-bold text-clr54 text-center py-md-5">
              Our Education Partners
            </h2>
          </div>
          <div className="row pb-md-5">
            <div className="col-md-12 d-flex justify-content-around my-md-5">
              <img src={schoolLogo} alt="school-logo" />
              <img src={schoolLogo} alt="school-logo" />
              <img src={schoolLogo} alt="school-logo" />
            </div>
            <div className="col-md-10 mx-auto d-flex justify-content-around mb-md-5">
              <img src={schoolLogo} alt="school-logo" />
              <img src={schoolLogo} alt="school-logo" />
            </div>
          </div>
        </div>
      </div> */}
      <div className="overflow-hidden bg-pink pb-4">
        <div className="container">
          <h2 className="fnt-lg-35 fnt-lg-bold text-clr54 text-center pt-3 pt-md-5 pb-md-1">
            What our partners says
          </h2>
        </div>
        <Carousel
          swipeable={false}
          draggable={false}
          showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={5000}
          // keyBoardControl={true}
          // customTransition=".5 ease-in-out"
          // transitionDuration={500}
          // containerClass="carousel-container"
          // removeArrowOnDeviceType={["tablet", "mobile"]}
          // deviceType={this.props.deviceType}
          // dotListClass="custom-dot-list-style"
          // itemClass="carousel-item-padding-40-px"
        >
          <div className="chat">
            <div className="chat-center">
              <p className="fnt-md-16 fnt-12 fnt-lg-500 fnt-500 text-white">
                Now I’m able to see where exactly in which subject which chapter
                my child’s concept is not clear. I can talk to teacher about it
                and even see how other children in class performing in same
                topics.
              </p>
              <p className="fnt-md-20 fnt-500 fnt-12 fnt-lg-500 text-white">
                - Parent
              </p>
            </div>
            <img
              className="px-md-2 chat-box w-100"
              src={Chatbox03}
              alt="Chatbox03"
            />
          </div>
          <div className="chat">
            <div className="chat-center">
              <p className="fnt-md-16 fnt-12 fnt-lg-500 text-white mb-0 d-md-none">
                I really like our school’s new mobile app for students and
                parents. The most interesting thing about the app is their
                “Question of the Day”, it really triggers an interesting
                conversation with my child during dinner each day. Really loved
                the idea behind it.
              </p>
              <p className="fnt-md-16 fnt-12 fnt-lg-500 text-white mb-0 d-none d-md-block">
                I really like our school’s new mobile app for students and
                parents. The most interesting thing about the app is their
                “Question of the Day”, it really triggers an interesting
                conversation with my child during dinner each day. Really loved
                the idea behind it.
              </p>
              <p className="fnt-md-20 fnt-12 fnt-lg-500 text-white">- Parent</p>
            </div>
            <img
              className="px-md-2 chat-box w-100"
              src={Chatbox01}
              alt="Chatbox01"
            />
          </div>
          <div className="chat">
            <div className="chat-center">
              <p className="fnt-md-16 fnt-500 fnt-12 fnt-lg-500 text-white">
                Actuate Minds have triggered different quotient of each
                students. Now they are asking though provoking questions in the
                class and I can even see where each student is lagging behind.
              </p>
              <p className="fnt-md-20 fnt-500 fnt-12 fnt-lg-500 text-white">
                - Teacher
              </p>
            </div>
            <img
              className="px-md-2 chat-box w-100"
              src={Chatbox02}
              alt="Chatbox02"
            />
          </div>
        </Carousel>
      </div>
    </>
  )
}

export default HomePage
